.contactpage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 40px;
}
.contactpage-content {
  display: flex;
  column-gap: 40px;
  justify-content: center;
  align-items: center;
}
.map-container {
  width: 600px;
  height: 500px;
  background-color: #46973b;
  border: 2px solid #111111;
  border-radius: 20px;
}
.map-container > iframe {
  width: 600px;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  border: 0;
}
.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 500px;
  background-color: #ffeb9a;
  border-radius: 20px;
  text-align: left;
  padding-left: 30px;
}

/* Responsive at 1440px */
@media screen and (max-width: 1440px) {
  .contactpage-content {
    flex-direction: column;
    row-gap: 40px;
  }
}

/* Responsive at 475px */
@media screen and (max-width: 475px) {
  .contactpage {
    padding-left: 20px;
    padding-right: 20px;
  }
  .map-container {
    width: 380px;
  }
  .map-container > iframe {
    width: 380px;
  }
  .contact-info {
    width: 320px;
    padding-right: 30px;
  }
}
/* Responsive at 426px */
@media screen and (max-width: 426px) {
  .contactpage {
    padding-left: 20px;
    padding-right: 20px;
  }
  .map-container {
    width: 380px;
  }
  .map-container > iframe {
    width: 380px;
  }
  .contact-info {
    width: 320px;
    padding-right: 30px;
  }
}

/* Responsive at 375px */
@media screen and (max-width: 376px) {
  .map-container {
    width: 320px;
    height: 400px;
  }
  .map-container > iframe {
    width: 320px;
    height: 400px;
  }
  .contact-info {
    width: 280px;

    padding-right: 30px;
  }
}

/* Responsive at 320px */
@media screen and (max-width: 320px) {
  .map-container {
    width: 300px;
    height: 400px;
  }
  .map-container > iframe {
    width: 300px;
    height: 400px;
  }
  .contact-info {
    width: 240px;
  }
}
